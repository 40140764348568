<template>
  <div>
    <v-card>
      <v-card-text>
        <v-form ref="form" lazy-validation @submit.prevent="buscar">
          <v-row>
            <v-col cols="6" sm="3" md="3" lg="3">
              <v-text-field
                label="Data Início"
                ref="dataInicio"
                outlined
                dense
                v-model="form.dataInicio"
                :rules="[rules.required]"
                v-mask="'##/##/####'"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="3" lg="3">
              <v-text-field
                label="Data Final"
                ref="dataFinal"
                outlined
                dense
                v-model="form.dataFim"
                :rules="[rules.required, rules.valid]"
                v-mask="'##/##/####'"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="2" md="2" lg="2">
              <v-row>
                <v-checkbox
                  v-model="opcoes"
                  label="Saída"
                  color="primary"
                  value="saida"
                  hide-details
                  class="mr-2"
                ></v-checkbox>
                <v-checkbox
                  v-model="opcoes"
                  label="Entrada"
                  color="primary"
                  value="entrada"
                  hide-details
                ></v-checkbox>
              </v-row>
            </v-col>
            <v-col cols="6" sm="2" md="2" lg="2" align="right">
              <v-btn
                :dark="!loading"
                depressed
                color="primary"
                type="submit"
                :loading="loading"
                :disabled="loading"
                >Buscar</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Relatorio from "@/service/relatorio";
import { saveAs } from "file-saver";
import moment from "moment";
export default {
  name: "RelatorioPeriodo",

  data() {
    return {
      loading: false,
      form: {
        dataInicio: "",
        dataFim: "",
      },
      rules: {
        required: (value) => !!value || "Obrigatório",
        valid: (value) =>
          !!moment(value, "DD/MM/YYYY").isValid() || "Data Inválida",
      },
      opcoes: ["saida", "entrada"],
    };
  },
  computed: {
    tipo() {
      if (!this.opcoes) return "";
      if (this.opcoes.length == 2) return "ambas";
      if (this.opcoes.length == 1) {
        return this.opcoes[0] == "saida" ? "saida" : "Entrada";
      }
      return "";
    },
    valido() {
      return (
        moment(this.form.dataInicio, "DD/MM/YYYY").isValid() &&
        moment(this.form.dataFim, "DD/MM/YYYY").isValid() &&
        moment(this.form.dataInicio, "DD/MM/YYYY").isBefore(
          moment(this.form.dataFim, "DD/MM/YYYY"),
          "day"
        )
      );
    },
  },

  methods: {
    async buscar() {
      if (!this.$refs.form.validate()) return;

      if (!this.valido) {
        this.$notify({
          title: "Datas inválidas",
          type: "error",
        });
        return;
      }
      this.loading = true;

      const payload = {
        dataInicio: moment(this.form.dataInicio, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        dataFim: moment(this.form.dataFim, "DD/MM/YYYY").format("YYYY-MM-DD"),
        tipo: this.tipo,
      };
      try {
        const resp = await Relatorio.contasPeriodo(payload);
        if (resp.size == 0) {
          this.$notify({
            title: "Nenhuma informação encontrada",
            type: "info",
          });
          return;
        }
        saveAs(resp, `Contas.xlsx`);
      } catch (error) {
        console.log(error);
        this.$notify({
          title: "Nenhuma informação encontrada",
          type: "info",
        });
        this.loading = false;
      }
      this.loading = false;
    },
  },
};
</script>

<style></style>
